
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    


    .c-sticky-notification-layout {
        display: flex;

        &__illustration {
            margin-right: $nu-spacer-2;

            @include breakpoint(medium) {
                margin-right: $nu-spacer-3;
            }
        }

        &__text-container {
            flex-grow: 1;
        }

        &__text {
            @include breakpoint(medium) {
                display: inline-flex;
                padding-right: $nu-spacer-2;
            }
        }

        &__pipe {
            display: none;
            width: 1px;
            height: 16px;
            vertical-align: middle;
            background-color: $nu-gray--medium;

            @include breakpoint(medium) {
                display: inline-flex;
                margin-right: $nu-spacer-2;
            }
        }
    }

