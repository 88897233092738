
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .c-sticky-notification {
        position: relative;
        min-height: 48px;
        display: flex;
        box-shadow: $sticky-notification-shadow;
        margin: 0 $nu-spacer-1;

        @include breakpoint(medium) {
            margin: 0 $nu-spacer-3;
        }

        &__outer {
            position: fixed;
            z-index: map-get($zindex, braze-sticky-notification);
            bottom: $nu-spacer-3;
            left: 0;
            right: 0;

            @include breakpoint(medium) {
                bottom: $nu-spacer-4;
            }
        }

        &__slot-contents {
            flex-grow: 1;
            padding: $nu-spacer-2 0 $nu-spacer-2 $nu-spacer-2;
            cursor: pointer;

            @include breakpoint(medium) {
                padding: $nu-spacer-2 0 $nu-spacer-2 $nu-spacer-3;
            }
        }

        &__close-btn {
            width: 52px;
            height: 52px;
            cursor: pointer;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
        }
    }
